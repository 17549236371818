import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import ReactGA from 'react-ga';


export default class ReactPlayerGA extends ReactPlayer {
  render() {
    // Place onXXX after the {...this.props} to avoid our settings from being 
    // stomped by the defaults.
    return(
      <ReactPlayer
        {...this.props}
        onStart={this.onStart.bind(this)}
        onEnded={this.onEnded.bind(this)}
      />
    )
  }

  onPlay() {
    ReactGA.event({
      category: 'Video',
      action: 'Play',
      label: this.props.videoName,
      videoUrl: this.props.url
    });
  }

  onStart() {
    ReactGA.event({
      category: 'Video',
      action: 'Start',
      label: this.props.videoName,
      videoUrl: this.props.url
    });
  }

  onEnded() {
    ReactGA.event({
      category: 'Video',
      action: 'Complete',
      label: this.props.videoName,
      videoUrl: this.props.url
    })
  }
}
