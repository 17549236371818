import React from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link
} from 'react-router-dom'
import './App.css';
import ReactPlayerGA from './ReactPlayerGA.js'


function App() {
    ReactGA.initialize('UA-113441955-2');
    return (
      <Router>
        <div className="App-header">
          <div>          
            <Link className="App-link" to="/"><img src="/jt.png" style={{width: '30px', height: '30px'}} alt="jtanner.org"/></Link>
          </div>
          <div>
            Gallery
          </div>
          <div/>
        </div>
        <div className="App">
          <Switch>
            <Route path="/videos/:id" component={VideoDetails}/>
            <Route path="/" component={VideoList}/>
          </Switch>
        </div>
     </Router>
  );
}

class VideoList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoaded: false,
      videos: []
    }
  }

  componentDidMount() {
    fetch("https://gallery-api.jtanner.org/gallery/v1/videos")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            videos: result.Items
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      );
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const {error, isLoaded, videos} = this.state;
    if(error) {
      return <div className="status-message">Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div className="status-message">Loading...</div>
    } else {
      return(
        <div className="video-list">
          {videos.map(video => (
            <div className='video-list-item'>
              <ReactPlayerGA 
                videoName={video.name}
                width="100%"
                height="100%"
                url={video.uri}
                controls
                light={video.thumbnail}
                playing
                volume={1}
                muted />
            </div>
        ))}
        </div>
    );
    }
  }

}

class VideoDetails extends React.Component {
  state = {
    id: null
  }

  componentDidMount() {
    const { id } = this.props.match.params

    fetch("https://gallery-api.jtanner.org/gallery/v1/videos/" + id)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          id: id,
          isLoaded: true,
          videos: result.Items
        });
      },
      (error) => {
        this.setState({
          id: id,
          isLoaded: true,
          error
        })
      }
    );

  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const {error, isLoaded, videos} = this.state;
    if(error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return(
        <div className="video-detail">
          {videos.map(video => (
            <div className="player-wrapper">
              <ReactPlayerGA 
                className="react-player"
                videoName={video.name}
                width="100%"
                height="100%"
                url={video.uri}
                controls
              />
            </div>
        ))}
        </div>
    );
    }
  }
}

export default App;
